<template lang="pug">
    div
        .flex.justify-space-between
            BackLink(
                :backRoute="{name: 'episodes', params: {clientId: clientId}}"
                :linkText="episodeLinkText"
            )
        div.absolute.inset-0.flex.justify-center.items-center.z-10(
            v-show="!iFrameLoaded"
        )
            Loader(
                class="w-16 h-16 align-center"
            )
        vue-friendly-iframe(
            v-show="iFrameLoaded"
            ref="surveyCloudForm"
            :class-name="'flex flex-col w-full min-h-full form-iframe'"
            :src="url"
            name="surveyCloudForm"
            @load="onLoad"
        )
</template>

<script>
import SubaccordionCompleteSvg from '@/assets/icons/form/subaccordion_icon_complete.svg'
import TypeIcon from '@/components/partials/TypeIcon'
import { mapActions, mapGetters, mapState } from 'vuex'
import GET_EPISODE from '@/graphql/queries/getEpisode.gql'
import camelcaseKeys from 'camelcase-keys'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import { SharedMethods } from '@/components/shared/mixins/sharedMixins'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { FormBuilderMethods } from '@/components/shared/mixins/formMixins'
import BackLink from '@/components/shared/BackLink'
import Loader from '@/assets/loader.svg'
import { WindowEventMixin } from '@/components/shared/mixins/eventHelpers'
import { getStatus, percentage } from '@/components/shared/modules/forms'

export default {
    name: 'Form',
    components: {
        SubaccordionCompleteSvg,
        TypeIcon,
        BackLink,
        Loader
    },
    mixins: [ EpisodeHelpers, SharedMethods, MessageDialog, FormBuilderMethods, WindowEventMixin ],
    apollo: {
        episode: {
            query: GET_EPISODE,
            variables() {
                return {
                    episodeId: Number(this.currentEpisodeId)
                }
            },
            skip() {
                return !this.currentEpisodeId || Object.keys(this.currentEpisode).length > 0
            },
            update: ({ episode }) => {
                return camelcaseKeys(episode, {deep: true})
            },
            result({ data: { episode } }) {
                this.foundEpisode = camelcaseKeys(episode || {}, { deep: true })

                this.$store.commit('SET_CURRENT_EPISODE', this.foundEpisode)
            }
        },
    },
    data(){
        return {
            totalProgress: 33,
            currentEpisodeId: this.$route.params.episodeId,
            clientId: this.$route.params.clientId,
            formSetId: this.$route.params.formSetId,
            dueDate: this.$route.query.d,
            foundEpisode: {},
            episodeForm: {
                form: {}
            },
            questionSets: [],
            totalQuestions: 0,
            totalComplete: 0,
            percentDone: 0,
            formAnswerId: this.$route.query.fai,
            iframeForm: null,
            iframeChild: process.env.VUE_APP_IFRAME_CHILD,
            // tenantId: '454fc900-9525-4c90-95cf-348999547038',
            url: '',
            iFrameLoaded: false
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.foundEpisode = this.currentEpisode || {}
        this.url = `${this.iframeChild}/DynamicForm?tenant=${this.tenantId}&fsi=${this.formSetId}&fai=${this.formAnswerId}&ext=t&uid=${this.loggedInUser.userId}`
        this.attachLoadingEvent()
    },
    methods: {
        ...mapActions(['openModal', 'closeModal']),
        getStatus: getStatus,
        percentage: percentage,
        onLoad() {
            // this.loading = false
            // this.iFrameLoaded = true
            console.log('===========iframe loaded and ready to send form answer===========')

            const iframeEl = this.$refs.surveyCloudForm.iframeEl
            if (iframeEl) {
                // here you can make the height, I delete it first, then I make it again
                iframeEl.height = ''
                iframeEl.height = iframeEl.contentWindow.document.body.scrollHeight + 'px'
                this.$refs.surveyCloudForm.$el.height = iframeEl.height
                this.iFrameLoaded = true
            }
            // this.sendFormAnswerId()
        },
        windowEventHandler({ data }) {
            if (data) {
                if (data === 'formLoaded') {
                    this.iFrameLoaded = true
                    return
                }
                // this part is for the save form
                const modalText = 'Submitting form, please do not click anywhere until submission is complete.'
                if (data === 'showLoader') this.openModal({payload: {text: modalText}, modalName: 'ModalPleaseWait'})//show loader
                else this.closeModal() //hide loader
                console.log('received message from iframe')
            }
        },
        attachLoadingEvent() {
            const eventFn = window.addEventListener || window.window.attachEvent
            // we check if we have addEventListener since the message event that we need to subscribe to is different
            const messageEvent = window.addEventListener ? 'message' : 'onmessage'
            eventFn(messageEvent, this.windowEventHandler)
        },
        removeEventListener() {
            const eventFn = window.removeEventListener || window.window.detachEvent
            // we check if we have addEventListener since the message event that we need to subscribe to is different
            const messageEvent = window.removeEventListener ? 'message' : 'onmessage'
            eventFn(messageEvent, this.windowEventHandler, false)
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            currentEpisode: state => state.app.currentEpisode,
            siteSections: state => state.app.siteSections,
            messageResult: state => state.app.messageResult
        }),
        ...mapGetters(['tenantId']),
        episodeLinkText() {
            return `EPISODE: ${this.getSiteSection(this.episode)} ${'&nbsp;'} ${'&nbsp;'}  ${this.getDateRange(this.episode)}`
        },
        episode() {
            return this.currentEpisode || this.foundEpisode || {}
        },
        iconObj() {
            return {iconName: this.title?.replace(' ', '_').toUpperCase()}
        },
        formDueDate() {
            return this.episodeForm?.dueDate
        },
        title() {
            console.log('values from route params and the episode form we have', this.$route.query?.t, this.episodeForm)
            return this.$route.query?.t || this.episodeForm.formTitle || ''
        },
        auth0Id() {
            console.log('auth user', this.$auth.user)
            return this.$auth.user?.sub
        }
    },
    watch: {
        episode() {
            if (this.episode && Object.keys(this.episode).length > 0) this.foundEpisode = this.episode
        },
        messageResult() {
            if (this.messageResult && Object.keys(this.messageResult).length) {
                this.message = this.messageResult.message
                this.type = this.messageResult.type
                this.showMessage({ duration: 5000 })
            }
        },
    },
    beforeDestroy() {
        this.removeEventListener()
    }
}
</script>
