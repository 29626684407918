const percentage = (totalComplete, totalQuestions) => {
    return Math.floor(((totalComplete || 0) / (totalQuestions || 1)) * 100)
}

const getStatus = (totalComplete, totalQuestions) => {
    return totalQuestions != null && totalComplete === totalQuestions && totalQuestions > 0 ? 'Completed' : 'Incomplete'
}

export {
    percentage,
    getStatus
}
