const WindowEventMixin = {
    methods: {
        attachEventListener(fn) {
            const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
            const eventer = window[eventMethod]
            const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
            // Listen to message from child window
            eventer(messageEvent, fn, false)
        }
    }
}

export {
    WindowEventMixin
}
